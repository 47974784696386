<template>
  <div class="tools">
    <div class="tools__nav">
      <div class="tools__nav__container">
        <slot name="nav-left"></slot>
      </div>
      <div class="tools__nav__container">
        <slot name="nav-right"></slot>
      </div>
    </div>
    <div class="tools__content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TheTools'
}
</script>

<style lang="scss">
.tools{
  @media print {
    display: none !important;
  }
  @include media("small-medium"){
    display: flex;
    justify-content: flex-start;
    flex-direction: column-reverse;
  }

  padding-top: layout("spacing");
  padding-bottom: layout("spacing");

  &__nav{
    @include media("large"){
      border-top: 1px solid tint(black, 90%);
    }

    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid tint(black, 90%);
    padding: layout("spacing")*0.6 0;

    &__container{
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    // Tools Buttons

    .tools-button{
      &:not(:last-of-type){
        border-right: 1px solid tint(black, 90%);
      }
    }

    // Tools Counter

    .tools-counter{
      margin-left: auto;
    }
  }

  &__content{
    @include media("small-medium"){
      border-top: 1px solid tint(black, 90%);
    }

    .tools-button{
      @include media("small-medium"){
        width: 100%;
        padding: layout("spacing")*0.6 0;
        border-bottom: 1px solid tint(black, 90%);
      }
      @include media("large"){
        display: none;
      }
    }
  }
}
</style>
