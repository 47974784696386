<template>
  <div class="tools-views">
    <div class="tools-views__container">
      <TheToolsButton :class="calendarClasses" :icon="'events'" :text="'Calendar View'" @click="handleCalendarView" />
      <TheToolsButton :class="listClasses" :icon="'listing'" :text="'List View'" @click="handleListView" />
      <TheToolsButton :class="tableClasses" :icon="'window'" :text="'Table View'" @click="handleTableView" />
    </div>
  </div>
</template>

<script>
import TheToolsButton from './TheToolsButton'
export default {
  name: 'TheToolsViews',
  props: {
    currentView: {
      type: String,
      required: true
    }
  },
  components: {
    TheToolsButton
  },
  computed: {
    calendarClasses () {
      return [
        { 'is-active': this.currentView === 'Calendar View' }
      ]
    },
    listClasses () {
      return [
        { 'is-active': this.currentView === 'Agenda List View' }
      ]
    },
    tableClasses () {
      return [
        { 'is-active': this.currentView === 'Agenda Table View' }
      ]
    }
  },
  methods: {
    handleCalendarView () {
      this.$router.push({ name: 'Calendar View' })
    },
    handleListView () {
      this.$router.push({ name: 'Agenda List View' })
    },
    handleTableView () {
      this.$router.push({ name: 'Agenda Table View' })
    }
  }
}
</script>

<style lang="scss">
.tools-views{
  @include media("small-medium"){
    background-color: colors("primary");
    padding: layout("spacing");
    margin: 0 (- layout("spacing"));
    width: calc(100% + #{layout("spacing") * 2});
  }

  &__container{
    @include media("small"){
      flex-direction: column;
    }

    @include media("small-medium"){
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: stretch;
      width: 100%;
    }

    @include media("large"){
      justify-content: flex-start;
      align-items: center;
    }
    display: flex;
  }

  .tools-button{
    @include media("small"){
      width: 100%;

      &:not(:last-of-type){
        margin-bottom: layout("spacing")/2;
      }
    }
    @include media("medium"){
      width: calc((100% - #{layout("spacing")} * 3) / 3);
      margin: layout("spacing")/2;
    }
    @include media("small-medium"){
      color: white;
      padding: 0.3rem layout("spacing");
      border: 1px solid white;
      border-radius: layout("border-radius");

      .icon{
        color: white;
      }
    }

  }
}
</style>
