<template>
  <a :class="mainClasses" href="#" @click.prevent.stop="handleClick">
    <BaseIcon class="tools-button__icon" :type="icon" v-if="icon" />
    <span class="tools-button__text">{{ text }}</span>
    <BaseIcon class="tools-button__chevron" :type="chevronType" v-if="isTrigger" />
    <ElementTag v-if="tag !== null" class="is-yellow" :text="String(tag)" />
  </a>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BaseIcon from '@base/BaseIcon'
import ElementTag from '@elements/ElementTag'

export default {
  name: 'TheToolsButton',
  props: {
    text: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    type: {
      type: String,
      required: false,
      default: ''
    },
    tag: {
      type: [Number, String],
      required: false,
      default: null
    },
    active: {
      type: Boolean,
      required: false
    }
  },
  components: {
    ElementTag,
    BaseIcon
  },
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    ...mapState('filters', [
      'closeDropdowns'
    ]),
    isTrigger () {
      return this.type === 'trigger'
    },
    mainClasses () {
      return [
        'tools-button',
        { 'is-disabled': this.disabled }
      ]
    },
    chevronType () {
      return !this.isOpen && this.isTrigger ? 'chevron-down' : 'chevron-up'
    },
    disabled () {
      return this.tag === 0
    }
  },
  watch: {
    active () {
      this.isOpen = this.active
    },
    closeDropdowns () {
      if (this.closeDropdowns && this.isOpen) {
        this.isOpen = false
        this.$emit('click', false)
      }
    }
  },
  methods: {
    ...mapActions('filters', [
      'updateCloseDropdowns'
    ]),
    handleClick () {
      this.isOpen = this.isTrigger ? !this.isOpen : false
      this.$emit('click', this.isOpen)
    }
  }
}
</script>

<style lang="scss">
.tools-button{
  $spacing: layout("spacing")*0.4;

  @include media("large"){
    padding: 0 layout("spacing");
  }

  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: colors("font");

  @include hover{
    color: colors("font");
  }

  &.is-active{
    @include media("small-medium"){
      background-color: white;
      .tools-button__icon::before{
        color: colors("primary");
      }
    }

    .tools-button__text{
      color: colors("primary");
      font-weight: font-weights("medium");
    }
  }

  // Icon

  &__icon{
    flex: 0 1 auto;
    color: colors("primary");
    margin-right: $spacing;

    &.icon-events{
      font-size: 1.9rem;
    }

    &.icon-listing{
      font-size: 1.3rem;
      margin-top: 0.4rem;
      margin-bottom: 0.4rem;
    }

    &.icon-window{
      font-size: 1.7rem;
    }
  }

  // Text

  &__text{
    flex: 0 1 auto;
    font-size: 1.4rem;
  }

  // Chevron

  &__chevron{
    flex: 0 1 auto;
    color: colors("primary");
    padding-left: $spacing;
    margin-left: auto;
    font-size: 1.2rem;
  }

  .tag{
    @include media("small-medium"){
      margin-left: auto;
    }
    margin-left: $spacing;
  }
}
</style>
