<template>
  <div class="tools-legend">
    <CalendarLegend :items="legendItems" v-if="legendItems" />
  </div>
</template>

<script>
import CalendarLegend from '@blocks/calendar/CalendarLegend'
import legendItems from '@mixins/legendItems'

export default {
  name: 'TheToolsLegend',
  mixins: [legendItems],
  components: {
    CalendarLegend
  }
}
</script>

<style lang="scss">
  .tools-legend{
    @include media("small-medium"){
      padding: layout("spacing");
      margin: 0 (- layout("spacing"));
    }
    @include media("large"){
      padding: layout("spacing")*1.5;
      margin: 0 (- layout("spacing"));
    }

    width: calc(100% + #{layout("spacing")*2});
    color: white;
    background-color: colors("primary");

    .calendar-legend {
      margin-top: 0;
      span {
        white-space: normal
      }
    }
  }
</style>
