<template>
  <div class="tools-dropdown">
    <TheToolsButton
      :class="'tools-dropdown__trigger'"
      :text="trigger.text"
      :icon="trigger.icon"
      :type="'trigger'"
      @click="handleClick"
    />

    <div class="tools-dropdown__content" v-show="active">
      <!-- SEARCH -->
      <TheToolsSearchBar :search-type="searchType" v-if="hasSearch" />
      <!-- LEGEND -->
      <TheToolsLegend v-else-if="hasLegend" />
      <!-- VIEW -->
      <TheToolsViews :current-view="currentView" v-else-if="hasViews" />
      <!-- FILTERS -->
      <TheFilters v-else-if="hasFilters" :type="filtersType" />
    </div>
  </div>
</template>

<script>
import TheToolsButton from './TheToolsButton'
import TheFilters from '@blocks/filters/TheFilters'
import TheToolsSearchBar from './TheToolsSearchBar'
import TheToolsLegend from './TheToolsLegend'
import TheToolsViews from './TheToolsViews'

export default {
  name: 'TheToolsDropdown',
  props: {
    type: {
      type: String,
      required: true
    },
    filtersType: {
      type: String,
      required: false,
      default: ''
    },
    searchType: {
      type: String,
      required: false,
      default: ''
    },
    trigger: {
      type: Object,
      required: true,
      default: () => {
        return {
          text: '',
          icon: '',
          type: ''
        }
      }
    },
    forceActive: {
      type: Boolean,
      required: false,
      default: false
    },
    currentView: {
      type: String,
      required: false,
      default: null
    }
  },
  components: {
    TheToolsButton,
    TheToolsSearchBar,
    TheToolsLegend,
    TheToolsViews,
    TheFilters
  },
  data () {
    return {
      active: false
    }
  },
  computed: {
    hasFilters () {
      return this.type === 'filters'
    },
    hasSearch () {
      return this.type === 'search'
    },
    hasLegend () {
      return this.type === 'legend'
    },
    hasViews () {
      return this.type === 'views'
    }
  },
  watch: {
    forceActive () {
      this.active = this.forceActive
    }
  },
  methods: {
    handleClick () {
      this.active = !this.active
    }
  }
}
</script>

<style lang="scss">
.tools-dropdown{
}
</style>
