<template>
  <div class="tools-search" :class="customClasses">
    <!-- Simple -->

    <form class="tools-search__simple" @submit.prevent="handleSubmit" v-if="!showSearchCategory">
      <div class="tools-search__content">
        <label class="tools-search__field" for="search-bar">
          <input
            id="search-bar"
            type="search"
            :placeholder="$t('message.filters.search.placeholder')"
            v-model="searchValue"
          />
        </label>
        <BaseSubmit
          class="button--search"
          icon="search"
          icon-position="center">
          <span>{{ $t(`message.modal.request.submit`) }}</span>
        </BaseSubmit>
      </div>

      <div class="tools-search__reset">
        <span>{{ $t(`message.modal.request.or`) }}</span>
        <a href="#" class="button-cancel" @click.prevent.stop="handleReset">{{ $t(`message.filters.search.reset`) }}</a>
      </div>
    </form>

    <!-- Advanced -->

    <form class="tools-search__advanced" @submit.prevent="handleSubmit" v-else>
      <div class="tools-search__actions">
        <BaseSubmit
          class="button--search"
          icon="reload"
          icon-position="right">
          <span>{{ $t('message.filters.buttons.update.text')}}</span>
        </BaseSubmit>
      </div>
      <div class="tools-search__content" >
        <label class="tools-search__field" for="search-bar">
          <input
            id="search-bar"
            type="search"
            :placeholder="$t('message.filters.search.placeholder')"
            v-model="searchValue"
          />
        </label>
        <select class="tools-search__select" v-if="showSearchCategory" v-model="searchCategory">
          <option
            v-for="category in categories"
            :value="category.value"
            :key="category.text">
            {{ category.text }}
          </option>
        </select>
        <TheCategoriesDate class="tools-search__date" />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseSubmit from '@base/BaseSubmit'
import TheCategoriesDate from '@blocks/categories/TheCategoriesDate'

export default {
  name: 'TheToolsSearchBar',
  props: {
    searchType: {
      type: String,
      required: false
    }
  },
  components: {
    BaseSubmit,
    TheCategoriesDate
  },
  data () {
    return {
      searchCategory: '',
      searchValue: ''
    }
  },
  watch: {
    search () {
      if (this.searchValue !== this.search.value) {
        this.searchValue = this.search.value
      }
    }
  },
  computed: {
    ...mapGetters({
      search: 'filters/getSearch'
    }),
    categories () {
      return [...this.$t(`message.filters.categories`)]
    },
    showSearchCategory () {
      return this.searchType === 'search-category'
    },
    customClasses () {
      return [
        {
          'is-search-category': this.showSearchCategory
        }
      ]
    }
  },

  mounted () {
    this.searchValue = this.search.value
    this.searchCategory = this.categories[0].value
    this.handleSubmit()
  },

  methods: {
    ...mapActions({
      updateSearch: 'filters/updateSearch',
      fetchData: 'filters/fetchData'
    }),
    handleSubmit () {
      this.updateSearch({
        value: this.searchValue,
        category: this.searchCategory
      })
      this.loadData()
    },
    handleReset () {
      this.updateSearch({
        value: '',
        category: ''
      })
      this.$nextTick(() => {
        this.loadData()
      })
    },
    loadData () {
      this.fetchData({ route: this.$route.name, context: 'filters' })
    }
  }
}
</script>

<style lang="scss">
  .tools-search{
    @include media("small-medium"){
      padding: layout("spacing")*1;
      margin: 0 (- layout("spacing"));
    }
    @include media("large"){
      padding: layout("spacing")*1;
      margin: 0 (- layout("spacing"));
    }

    width: calc(100% + #{layout("spacing")*2});
    background-color: colors("primary");

    // Simple

    &__simple{
      position: relative;
      width: 100%;
      flex: 0 1 auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .tools-search__content{
        position: relative;
        flex: 1 1 auto;
      }

      .button--search{
        position: absolute;
        top: 50%;
        padding: 0;
        right: 1.5rem;
        margin-top: -1rem;

        .icon{
          font-size: 1.8rem;
        }
      }
    }

    // Advanced

    &__advanced{
      .tools-search__content{
        flex: 1 1 auto;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;
      }

      .tools-search__field{
        @include media("small"){
          margin-right: 0;
          margin-bottom: layout("spacing");
        }
        @include media("medium-large"){
          width: calc(50% - #{layout("spacing")/2});
          flex: 0 1 auto;
        }
      }

      .tools-search__select{
        @include media("medium-large"){
          width: calc(50% - #{layout("spacing")/2});
          flex: 0 1 auto;
          margin-left: 0;
        }
      }

      .tools-search__date{
        @include media("small"){
          width: 100%;
          flex-direction: column;
          margin: 0;

          > div{
            width: 100%;
            margin-left: 0;
            margin-right: 0;
          }
        }
        @include media("medium"){
          width: 100%;
          flex: 1 1 auto;
          margin: 0;
        }
        @include media("large-only") {
          width: 100%;
          flex: 1 1 auto;
          margin: 0;
        }
        @include media("xlarge"){
          width: calc(50% - #{layout("spacing")/2});
          flex: 0 1 auto;
          margin: 0;
        }

        > div{
          &:first-of-type{
            margin-left: 0;
          }
          &:last-of-type{
            margin-right: 0;
          }
        }
      }

     .button--search{
        @include background-color(shade(colors("primary"), 20%), white);
        @include text-color(white, shade(colors("primary"), 20%));
        @include hover{
          .icon{
            color: shade(colors("primary"), 20%);
          }
        }
        flex: 0 1 auto;
        margin-right: layout("spacing")/2;
        .icon{
          color: white;
          font-size: 1.6rem;
        }
      }
    }

    // Actions

    &__actions{
      margin-bottom: layout("spacing");
      display: flex;
      justify-content: center;
    }

    // Reset

    &__reset{
      margin-left: 1rem;
      font-size: 1.4rem;
      flex: 0 0 auto;
      color: white;

      .button-cancel{
        margin-left: 1rem;
        color: white;
        font-weight: font-weights('bold');
      }
    }

    // Field

    &__field{
      width: 100%;
      margin-bottom: 0;
      color: white;
      flex: 1 1 auto;

      input[type="search"]{
        padding: layout("spacing")*0.4;
        box-sizing: border-box;
      }
    }

    // Select

    &__select{
      padding: layout("spacing")*0.2 layout("spacing")*0.4;
    }

    // Default Input Search + Select

    input[type="search"],
    select{
      width: 100%;
      margin-bottom: 0;
      color: white;
      font-size: 1.5rem;
      border-radius: 4px;
      border: 1px solid white;
      background-color: transparent;
      font-weight: font-weights("bold");

      &:hover,
      &:focus{
        background-color: transparent;
      }
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: white;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: white;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: white;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: white;
    }
  }
</style>
