<template>
  <div class="filters" :class="customClasses">
    <TheFiltersActions @update="handleUpdate" @reset="handleReset" />
    <TheCategories :categories="categories" :context="'filters'" ref="categories" />
  </div>
</template>

<script>
import TheFiltersActions from './TheFiltersActions'
import TheCategories from '@blocks/categories/TheCategories'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TheFilters',
  components: {
    TheFiltersActions,
    TheCategories
  },
  props: {
    type: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      categories: 'filters/getCategories',
      selected: 'filters/getSelected',
      search: 'filters/getSearch'
    }),
    customClasses () {
      return [
        `filters--${this.type}`
      ]
    }
  },
  methods: {
    ...mapActions({
      reset: 'filters/reset',
      updateSelected: 'filters/updateSelected',
      fetchData: 'filters/fetchData'
    }),
    handleUpdate () {
      this.loadData()
    },
    handleReset () {
      this.reset()
      this.$nextTick(() => {
        this.loadData()
      })
    },
    loadData () {
      this.fetchData({ route: this.$route.name, context: 'filters' })
    }
  }
}
</script>

<style lang="scss">
  .filters{
    @include media("small-medium"){
      padding: layout("spacing");
      margin: 0 (- layout("spacing"));
    }
    @include media("large"){
      padding: layout("spacing")*1.5;
      margin: 0 (- layout("spacing"));
    }

    width: calc(100% + #{layout("spacing")*2});
    background-color: colors("primary");
  }
</style>
