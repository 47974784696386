<template>
  <div class="filter-actions">
    <BaseButton
      class="button--update"
      icon="reload"
      icon-position="right"
      @click.native="handleUpdateClick">
      {{ $t('message.filters.buttons.update.text')}}
    </BaseButton>
    <BaseButton
      class="button--reset"
      @click.native="handleResetClick">
      {{ $t('message.filters.buttons.reset.text')}}
    </BaseButton>
  </div>
</template>

<script>
import BaseButton from '@base/BaseButton'
export default {
  name: 'TheFiltersAction',
  components: {
    BaseButton
  },
  methods: {
    handleUpdateClick () {
      this.$emit('update')
    },
    handleResetClick () {
      this.$emit('reset')
    }
  }
}
</script>

<style lang="scss">
  .filter-actions{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: layout("spacing")*1.5;

    // Update

    .button--update{
      @include background-color(shade(colors("primary"), 20%), white);
      @include text-color(white, shade(colors("primary"), 20%));
      @include hover{
        .icon{
          color: shade(colors("primary"), 20%);
        }
      }

      flex: 0 1 auto;
      margin-right: layout("spacing")/2;

      .icon{
        color: white;
        font-size: 1.6rem;
      }
    }

    // Reset

    .button--reset{
      @include background-color(transparent, white);
      @include text-color(white, shade(colors("primary"), 20%));

      flex: 0 1 auto;
    }
  }
</style>
